<template>
  <tableau view="Practices/Practices" />
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
